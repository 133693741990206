export type SearchType = 'stickers' | 'gifs' | 'clips'

export const searchTypeToTitleType = (type: SearchType): string => {
    switch (type) {
        case 'stickers':
            return 'Stickers'
        case 'gifs':
            return 'GIFs'
        case 'clips':
            return 'Clips'
        default:
            return ''
    }
}