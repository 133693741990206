import { giphyAqua, giphyWhite } from '@giphy/colors'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

const PAGE_TITLE_FONT_SIZE = 26

const font = css`
    font-size: ${PAGE_TITLE_FONT_SIZE}px;
    font-weight: bold;
    line-height: 26px;
`
const containerCss = css`
    ${font}
    -webkit-font-smoothing: antialiased;
    color: ${giphyWhite};
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: 14px;
    padding-left: 5px;

    h1 {
        ${font}
    }
`

const Container = styled.div`
    ${containerCss}
`

const H1 = styled.h1``

const HighLightWord = styled.span`
    color: ${giphyAqua};
`

type PageTitleSubComponents = {
    HighLight: React.FC<PageTitleHighLightProps>
}

type PageTitleProps = {
    className?: string
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    children?: ReactNode
}

const PageTitle: React.FC<PageTitleProps> & PageTitleSubComponents = ({ tag = 'h1', children, className }) => {
    return (
        <Container className={className}>
            <H1 as={tag}>{children}</H1>
        </Container>
    )
}

type PageTitleHighLightProps = {
    children?: ReactNode
}

PageTitle.HighLight = ({ children }: PageTitleHighLightProps) => {
    return <HighLightWord>{children}</HighLightWord>
}

export default PageTitle
